<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useLessons } from "~/stores/useLessons";

const props = defineProps({
  isMultiple: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["onchange"]);

const mode = ref(props.isMultiple ? "tags" : "single");

const lessonStore = useLessons();

const { allUserLessons } = storeToRefs(lessonStore);

onMounted(() => {
  lessonStore.initializeLessons();
});

const placeholder = computed(() => {
  return allUserLessons.value.length > 0
    ? "Select a lesson"
    : "Loading lessons...";
});

const onChange = (value: any) => {
  var level = allUserLessons.value.find((l) => l.id == value);

  if (level) {
    emit("onchange", level.name);
  }
};
</script>

<template>
  <BaseSelect
    :mode="mode"
    :searchable="true"
    :can-clear="true"
    :options="allUserLessons"
    value-prop="id"
    label="name"
    :placeholder="placeholder"
    @change="onChange"
  />
</template>

<style scoped></style>
